import utils from "../utils/index"
const IP = process.env.VUE_APP_BASEURL;
/* eslint-disable no-console */
export default {
    // =========专家墙相关=========
    // 获取专家列表
    getExpertsList (param) {
        const url = IP + 'api/ext/expert-plan/expert-list';
        return utils.Ajax(url, param, 'GET')
    },
    initProfitMatchList (param) {
        const url = IP + 'api/ext/expert-plan/match-list';
        return utils.Ajax(url, param, 'GET')
    },
    profitPlanPublish (param) {
        const url = IP + 'api/ext/expert-plan/publish'
        return utils.Ajax(url, param, 'POST')
    },
    initScoreInfo (param) {
        const url = IP + 'api/ext/expert-plan/odds';
        return utils.Ajax(url, param, 'GET')
    },
    // ======三场盈利相关=======
    getExpertsList3profit (param) {
        const url = IP + 'api/ext/three-profit/expert-list';
        return utils.Ajax(url, param, 'GET')
    },
    initProfitMatchList3profit (param) {
        const url = IP + 'api/ext/three-profit/match-list';
        return utils.Ajax(url, param, 'GET')
    },
    profitPlanPublish3profit (param) {
        const url = IP + 'api/ext/three-profit/publish'
        return utils.Ajax(url, param, 'POST')
    },
    // ======情报相关=======
    // 获取情报比赛列表
    reportMatchList (param) {
        const url = IP + 'api/ext/report/match-list'
        return utils.Ajax(url, param, 'GET')
    },
    // 发布情报
    reportPublish (param) {
        const url = IP + 'api/ext/report/publish'
        return utils.Ajax(url, param, 'POST')
    },
    reportDetails (param) {
        const url = IP + 'api/ext/report/detail-list'
        return utils.Ajax(url, param, 'GET')
    },
    // =======竞彩长串相关=====
    getExpertsListlong (param) {
        const url = IP + 'api/ext/jc-long/expert-list';
        return utils.Ajax(url, param, 'GET')
    },
    initProfitMatchListlong (param) {
        const url = IP + 'api/ext/jc-long/match-list';
        return utils.Ajax(url, param, 'GET')
    },
    profitPlanPublishlong (param) {
        const url = IP + 'api/ext/jc-long/publish'
        return utils.Ajax(url, param, 'POST')
    }
}
