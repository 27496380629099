import axios from 'axios';
import md5 from 'js-md5';
import QRCode from 'qrcodejs2'
export default {
    // 操作本地缓存
    handleLocalStorage (method, key, value) {
        switch (method) {
        case 'get' : {
            const temp = window.localStorage.getItem(key);
            if (temp) {
                return temp
            } else {
                return false
            }
        }
        case 'set' : {
            window.localStorage.setItem(key, value);
            break
        }
        case 'remove': {
            window.localStorage.removeItem(key);
            break
        }
        default : {
            return false
        }
        }
    },
    // 请求
    Ajax (url, params, type) {
        /* eslint-disable */
        const axiosObj = axios.create();
        // axiosObj.defaults.withCredentials = true;
        axiosObj.interceptors.request.use(
            config => {
                config.headers['Authorization'] = 'Bearer' + this.handleLocalStorage('get', 'token');
                const yxDeviceId = this.frandomName(8);
                const yxSmid = this.handleLocalStorage('get', 'yx-smid')
                config.headers['yx-smid'] = yxSmid;
                config.headers["yx-device-id"] = yxDeviceId;
                config.headers["yx-app-type"] = 3;
                const randStr = this.frandomName(16);
                config.headers["yx-rand-str"] = randStr;
                config.headers["yx-sign"] = this.md5(yxDeviceId, 3, randStr);
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        let promise;
        return new Promise((resolve, reject) => {
            if (type === "GET") {
                let paramStr = '';
                for (let item in params) {
                    paramStr += item + "=" + params[item] + "&";
                }
                if (paramStr !== '') {
                    paramStr = paramStr.substr(0, paramStr.lastIndexOf("&"));
                }
                url = url + "?" + paramStr;
                promise = axiosObj.get(url, params);
            } else if (type === "POST") {
                promise = axiosObj.post(url, params);
            } else if (type === "PUT") {
                promise = axiosObj.put(url, params);
            }
            promise.then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err);
            })
        });
    },
    // 生成随机长度的字符串
    frandomName (len) {
        len = len || 16;
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var charsLength = chars.length;
        var str = '';
        for (let i = 0; i < len; i++) {
            str += chars.charAt(Math.floor(Math.random() * charsLength))
        }
        return new Date().getTime() + str
    },
    // 获取url的参数
    getQueryVariable (variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    },
    // md5生成签名
    md5 (_deviceId, _appType, _randStr) {
        const secretKey = "RIIUvt6hayjwD4vh";
        return md5(secretKey + _deviceId + _appType + _randStr)
    },
    // 检查返回值code，判断是否跳转到登录
    checkData (context, data) {
        if (data.code === 5401) {
            context.$router.push('/login')
        }
    },
    // 数字处理 大于1000的显示方法
    returnFloat (val) {
        let thousand = '10w+';
        if (val >= 100000) {
            return thousand;
        } else if (val >= 10000) {
            let value = Math.floor(parseFloat(val) / 10000 * 10) / 10;
            let xsd = value.toString().split(".");
            if (xsd.length === 1) {
                value = value.toString() + ".0";
                return value + 'w';
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString();
                }
                return value + 'w';
            }
        } else if (val >= 1000) {
            let value = Math.floor(parseFloat(val) / 1000 * 10) / 10;
            let xsd = value.toString().split(".");
            if (xsd.length === 1) {
                value = value.toString() + ".0";
                return value + 'k';
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString();
                }
                return value + 'k';
            }
        } else if (val < 1000) {
            return val;
        }
    },
    // 时间戳转化为时间
    timestampToTime (timespan, needYear) {
        var dateTime = new Date(timespan * 1000); // 时间戳为10位需*1000，13位的话不需乘1000
        var year = dateTime.getFullYear();
        var month = dateTime.getMonth() + 1;
        var day = dateTime.getDate();
        var hour = dateTime.getHours();
        var minute = dateTime.getMinutes();
        if (month <= 9) {
            month = '0' + month
        }
        if (day <= 9) {
            day = '0' + day
        }
        if (hour <= 9) {
            hour = '0' + hour
        }
        if (minute <= 9) {
            minute = '0' + minute
        }
        if (needYear) {
            return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
        } else {
            return month + '-' + day + ' ' + hour + ':' + minute;
        }
    },
    // 处理时间
    timestampToTimeTwo (time) {
        var dateTime = new Date(time * 1000); // 时间戳为10位需*1000，13位的话不需乘1000
        var year = dateTime.getFullYear();
        var month = (dateTime.getMonth() + 1) < 10 ? '0' + (dateTime.getMonth() + 1) : (dateTime.getMonth() + 1);
        var day = dateTime.getDate() < 10 ? '0' + dateTime.getDate() : dateTime.getDate();
        var hour = dateTime.getHours() < 10 ? '0' + dateTime.getHours() : dateTime.getHours();
        var minute = dateTime.getMinutes() < 10 ? '0' + dateTime.getMinutes() : dateTime.getMinutes();
        var now = new Date();
        var milliseconds;
        var timeSpanStr;
        milliseconds = now.getTime() - dateTime.getTime();
        if (milliseconds <= 1000 * 60 * 1) {
            timeSpanStr = '刚刚';
        } else if (milliseconds > 1000 * 60 * 1 && milliseconds <= 1000 * 60 * 60) {
            timeSpanStr = Math.round(milliseconds / (1000 * 60)) + '分钟前';
        } else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) {
            timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前';
        } else if (1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) {
            timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前';
        } else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
            timeSpanStr = year + '-' + month + '-' + day + "    " + hour + ":" + minute;
        } else {
            timeSpanStr = year + '-' + month + '-' + day + "    " + hour + ":" + minute;
        }
        return timeSpanStr;
    },
    // 生成二维码
    buildQrCode (url, size, id) {
        /* eslint-disable*/
        let qr = new QRCode(id, {
            width: size,
            height: size, // 高度
            text: url,
            render: 'canvas'
        })
    }
}
